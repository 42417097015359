<template>
  <div class="w1092">
      <div class="moneyread">
        <ul>
          <li><img src="@/assets/img/icon_check.svg" />결제양식에 따라 결제하시면 입금처리가 완료 됩니다.</li>
          <li><img src="@/assets/img/icon_check.svg" />입금 전 입금계좌정보를 꼭 확인해주세요.</li>
          <li><img src="@/assets/img/icon_check.svg" />입금관련 문제 발생시 고객센터로 문의 주시기 바랍니다.</li>
          <li><img src="@/assets/img/icon_check.svg" />충전요청 금액을 선택하거나, 직접 입력하여 충전할 수 있습니다. 입금자명과 계좌정보가 일치 하는지 확인해 주세요.</li>
        </ul>
      </div>

      <div class="moneyinfoappli">
        <div class="inputLeft">
          <p class="check"><img src="@/assets/img/icon_check2.svg" />입금자 이름이 다르면 입금확인을 할 수 없으니 주의하시기 바랍니다.</p>
          <p class="input"><input type="text" pattern="[0-9.,]+" class="numb" placeholder="입금자명" v-model="coinInfo.accountname"/></p>
          <div class="applibtns">
            <a @click="onSubmit('pc')">{{$t('front.cash.moneyCheckListL')}}</a>
          </div>
        </div>
        <div class="inputRight">
          <ul class="appliinput one">
            <li>예금주</li>
            <li>{{coinInfo.accountname}}</li>
          </ul>
          <ul class="appliinput one">
            <li>은행명</li>
            <li>{{coinInfo.bank}}</li>
          </ul>
          <ul class="appliinput one">
            <li>계좌번호</li>
            <li>{{coinInfo.accountnumber}}</li>
          </ul>
          <div class="appliinput two">
            <ul>
              <li><img src="@/assets/img/icon_coin.svg" />{{$t('front.cash.moneyHave')}}</li>
              <li><span>{{thousand(userData.cashAmt)}}</span> {{$t('front.common.money')}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="board">
        <ul class="boardw first">
          <li class="">{{$t('front.board.applyDay')}}</li>
          <li class="">{{$t('front.board.processMoney')}}</li>
          <li class="">{{$t('front.board.processBonus')}}</li>
          <li class="">{{$t('front.board.processDay')}}</li>
          <li class="">{{$t('front.board.processState')}}</li>
        </ul>
        <template v-if="cashList.length > 0">
          <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
            <li class="">{{dateFormat(item.regDate)}}</li>
            <li class="">{{thousand(item.cashAmt)}}</li>
            <li class="">0</li>
            <li class="">{{dateFormat(item.updDate)}}</li>
            <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
          </ul>
        </template>
        <template v-else>
          <ul class="boardw">
            <li>{{$t('front.common.notFoundList')}}</li>
          </ul>
        </template>
      </div>
      <div class="boardpage mt20 mt50">
        <pagination :pageNum="pageInfo.page"
                    :pageSize="pageInfo.count_per_list"
                    :totalCount="pageInfo.tatal_list_count"
                    @goToPage="onCashListSearch" />
      </div>
    </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { getCoinBankInfo, getSCoinPurchaseURL } from '@/api/cash'

export default {
  name: 'Charge',
  components: {
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    }
  },
  created () {
    this.getBankInfo()
    this.onCashListSearch()
  },
  data () {
    return {
      accountname: '',
      type: 'apply',
      isApplyBonus: false,
      bonus: 0,
      cashList: [],
      allChecked: false,
      cashType: 'in',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      },
      coinInfo: {
        partnercode: 'instar',
        userid: '',
        level: 1,
        bank: '',
        accountname: '',
        accountnumber: ''
      }
    }
  },
  methods: {
    thousand,
    getBankInfo () {
      getCoinBankInfo({}).then(res => {
        const result = res.data
        console.log(result.data)
        if (result.resultCode === '0') {
          const data = result.data
          this.coinInfo = {
            partnercode: 'instar',
            userid: data.userid,
            level: 1,
            bank: data.bank,
            accountname: data.depositor,
            accountnumber: data.account
          }
        }
      })
    },
    async onSubmit (device = 'mobile') {
      if (!this.coinInfo.accountname) {
        await this.onAlert('warningart', '입금자명을 입력해주세요.')
        return false
      }

      const confirm = await this.onConfirm('front.cash.confirmCharge')

      if (confirm) {
        getSCoinPurchaseURL(this.coinInfo).then(res => {
          const result = res.data
          if (result.resultCode === '0') {
            const data = result.data
            window.open(data.Message, 'window_name', 'width=600, height=800,toolbar=no,menubar=no,resizable=no,location=no,status=no,scrollbars=yes')
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.moneyread {padding: 26px 18px;font-weight: normal;text-align: left;border: solid 1px #53474b;background-color: #1e1b1c;color: #fff;font-size: 16px;margin-bottom: 30px;}
.moneyread ul li:first-child {margin-top: 0;}
.moneyread ul li {line-height: 1.5em;margin-top: 17px;}
.moneyread ul li img {margin-right: 10px;vertical-align: middle;}

.moneyinfo {display: flex;align-items: center;justify-content: space-between;gap: 27px;margin: 25px 0 60px;}
.applibtn {width: 300px;height: 88px;border-radius: 5px;background-image: linear-gradient(to bottom, #85e9e8, #005e68);font-size: 20px;color: #fff;display: flex;justify-content: center;align-items: center;text-align: center;}
.applistep {border: solid 1px #53474b;background-color: #1e1b1c;width: 1192px;color: #fff;padding: 20px;font-size: 16px;}
.applistep li {display: flex;gap: 12px;}
.applistep span {display: block;line-height: 1.5em;}

.moneyinfoappli {margin-bottom: 150px;display: flex;gap: 22px;color: #fff;}
.inputLeft {width: 670px;}
.inputLeft .input {box-sizing: border-box;width: 100%;height: 54px;border: solid 1px #53474b;font-size: 20px;display: flex;align-items: center;justify-content: end;gap: 10px;padding: 0 25px;margin-bottom: 25px;}
.inputLeft input {border: 0;background: none;color: #ff9ea8;font-size: 20px;text-align: right;}
.moneybtnwrap {overflow: hidden;}
.moneybtnwrap li {float: left;margin-left: 12px;margin-bottom: 14px;}
.moneybtnwrap li:nth-child(4n+1) {margin-left: 0;}
.moneybtnwrap a {display: flex;align-items: center;justify-content: center;color: #fff;font-size: 16px;width: 158px;height: 54px;border-radius: 5px;border: solid 1px #aa3b47;background-color: #1e1b1c;box-sizing: border-box;}
.moneybtnwrap .one a {border: solid 1px #6d6c7c;}
.inputLeft .check {font-size: 16px;color: #fff;display: flex;align-items: center;justify-content: center;gap: 12px;margin: 11px 0 25px;}
.applibtns a {border-radius: 5px;background-color: #9d303a;border: 1px solid #6d6d6d;width: 100%;height: 88px;color: #fff;font-size: 22px;display: flex;align-items: center;justify-content: center;}

.inputRight {border: solid 1px #53474b;width: 400px;}
.appliinput {display: flex;align-items: center;justify-content: space-between;color: #fff;margin: 0 25px;border-bottom: 1px solid #33323b;}
.appliinput:last-child {border-bottom: 0;}
.appliinput.top {font-size: 20px;padding: 28px 0 26px;}
.appliinput.two {padding: 23px 0 20px;display: block;font-size: 16px;}
.appliinput.two ul {display: flex;align-items: center;justify-content: space-between;color: #989898;}
.appliinput.two ul:first-child {color: #fff;}
.appliinput.two img {vertical-align: middle;margin-right: 10px;}
.appliinput.two ul span {font-size: 20px;margin-right: 10px;}
.appliinput.one {padding: 23px 0 20px;font-size: 18px;}
.appliinput.one input {max-width: 200px;border: 0;background: none;color: #ff9ea8;font-size: 22px;text-align: right;margin-right: 10px;}
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
